.faq .faq--wrapper section.faq--heading,
.faq .faq--wrapper section.accordion--wrapper,
.faq .faq--wrapper .accordion-item{
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}

.faq .faq--wrapper section.faq--heading div.faq-heading{
    padding-top: 56px;
}

.faq .faq--wrapper section.faq--heading div.faq-heading div.faq-heading--col,
.faq .faq--wrapper section.faq--heading .content,
.faq .faq--wrapper section.accordion--wrapper div.accordion--header,
.faq .faq--wrapper section.accordion--wrapper div.accordion--header .content{
    width: 100%;
    max-width: 1280px;
    padding-left: 48px;
    padding-right: 48px;
    margin-left: auto;
    margin-right: auto;
}

.faq .faq--wrapper section.faq--heading div.faq-heading div.faq-heading--col div.header{
    width: 100%;
    width: 58.3333333333%;
}

.faq .faq--wrapper section.faq--heading div div div.header h1{
    word-wrap: break-word;
    color: rgba(55,71,79,1);
    color: #333333;
    font-family: 'Nunito Sans', Arial;
    font-weight: 400;
    vertical-align: baseline;
    font-size: 27.0pt;
}
.faq .faq--wrapper section.faq--heading div div div.header .content hr{
    border-bottom: 2px solid #840000;
    border-top: none;
    opacity: .80;
}
.faq .faq--wrapper section.faq--heading div div div.header .content p{
    color: #000000;
    font-family: 'Nunito Sans', Arial;
    font-size: 12.0pt;
    font-weight: 400;
    vertical-align: baseline;
}

.faq .faq--wrapper .accordion-body,
.faq .faq--wrapper section.faq--heading div div div.header h1{
    padding: 14px 8px;

}

/* ACCORDION */

.faq .faq--wrapper .accordion-item{
    border: none;
    background-color: transparent;
}

.faq .faq--wrapper .accordion-button, 
.faq .faq--wrapper .accordion-button:focus,
.faq .faq--wrapper .accordion--wrapper .graph h2{
    background-color: transparent;
    padding: 0;
    color: #000000;
    font-family: 'Nunito Sans', Arial;
    font-size: 13.5pt;
    font-weight: 700;
    vertical-align: baseline;
    outline: 0;
    box-shadow: none;
}

.faq .faq--wrapper .accordion-body{
    /* padding: 1.25rem 0; */
    color: #333333;
    font-family: 'Nunito Sans', Arial;
    font-size: 12.5pt;
    font-weight: 400;
    vertical-align: baseline;
}

.faq .faq--wrapper .accordion hr{
    border-bottom: 1px solid rgb(0 0 0);
    border-top: none; 
    opacity: .80;
}
.faq .faq--wrapper .accordion .accordion-item .accordion-body ul{
    padding-inline-start: 20px;
}
.faq .faq--wrapper .accordion .accordion-item .accordion-body ul li.list-item{
    margin-bottom: 11pt;
    margin-top: 11pt;
}

.faq .faq--wrapper .accordion--wrapper .graph .graph-img{
    width: 100%;
    max-width: 1280px;
}

.faq .faq--wrapper .accordion--wrapper .graph .graph-img img{
    width: 100%;
}

.faq .faq--wrapper.colored-bg{
    background-color: rgba(242,242,242,1);
}

.accordion--wrapper .content .padded-ul li{
   margin-left: 10pt;
}

@media only screen and (min-width: 320px) and (max-width: 767px){
    .faq .faq--wrapper section.faq--heading div.faq-heading div.faq-heading--col,
    .faq .faq--wrapper section.faq--heading .content,
    .faq .faq--wrapper section.accordion--wrapper div.accordion--header,
    .faq .faq--wrapper section.accordion--wrapper div.accordion--header .content{
        /* max-width: max-content; */
        padding-left: 2.5%;
        padding-right: 2.5%;
    }
    .faq .faq--wrapper section.faq--heading div.faq-heading div.faq-heading--col div.header{
        width: 100%;
    }
}