.contact .content{
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 1280px;
    padding-left: 48px;
    padding-right: 48px;
}


.contact section{
    padding-bottom: 56px;
    padding-top: 112px;
    padding-left: 1.25%;
    padding-right: 1.25%;
}

.contact .contact--usa p, .contact .contact--ph p{
    margin: 0rem;
}

@media only screen and (min-width: 320px) {
    /* @media only screen and (min-width: 320px) and (max-width: 767px){ */
    .contact .content{
        /* max-width: max-content; */
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

}