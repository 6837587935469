footer.footer{
    background: rgb(0 0 0 / 89%);
    color: white;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    /* position: fixed; */
    bottom: 0;
}

.footer--wrapper{
    max-width: 1280px;
    padding: 40px 48px;
    margin: auto;
    width: 100%;
}

.footer--wrapper .logo img{
    width: 290px;
    /* max-width: 250px;
    height: auto; */
}

.footer--wrapper .contact{
    display: flex;
    font-size: 12pt;
    flex-wrap: wrap;
    width: 100%;
    margin: 5px 0;
    justify-content:flex-start;
    align-items: center;
}

.footer--wrapper .contact img{
    width: 45px;
    /* max-width: 45px;
    height: 25px; */
}

.footer--wrapper .contact img, .footer--wrapper .contact div{
    padding: 0 10px ;
}

footer div p{
    margin: 0;
}
footer div.reserved .footer--links{
    display: flex;
    gap: 10px;
    align-items: center;
}

footer div.reserved{
    color: #ffffffd6;
    font-size: 12pt;
    margin: 20px 0 0 0;
}

.footer--wrapper .reserved p a{
    text-decoration: none;
    color: #ffffffd6;
}

@media only screen and (max-width: 770px){
    .footer--wrapper{
        padding: 30px 4%;
    }
    .footer--wrapper .contact img, .footer--wrapper .contact div{
        padding: 0 5px;
    }
    /* .footer--wrapper .contact img{
        width: 100%;
        max-width: 35px;
        height: 23px;
    } */
    .footer--wrapper .logo img{
        max-width: 200px;
    }
}