.navbar-toggler:focus{
    box-shadow: none;
}
.navbar-brand{
    padding-left: 0.8rem;
}
.navbar-toggler-icon{
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAKklEQVR4nGNgGAVUAf///3f7////VQqxG+0MHIHg/2ikDD7wfzRShjcAALmalfSmo/mgAAAAAElFTkSuQmCC);
    width: 1.1em;
}
.offcanvas{
    background-color: black !important;
}
.offcanvas.offcanvas-start{
    width: 250px;
}
.offcanvas-header{
    padding: 2rem 1rem 1rem;
}
.offcanvas-header .btn-close{
    background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjklEQVR4nO2VwQ2AIBAEaUIiJWq58rCcMURfRPH2hJ/7IuF2R+GAEH6pAhYgOnyxeN+KVk5lBcIZXjxFa6twArarcAeSIbz2zKohdQtXIHjDLRC+hrcg9Ap/6JBcjeV2tvxJny83AFKv8Dhsie421HMYzeHheU6DWFoRL0Tpc1SI5xChQIZf18MfnF/hRgeqgBzeDZPClgAAAABJRU5ErkJggg==);
    opacity: 1;
    padding: 0.3rem;
}
.offcanvas-body{
    padding: 1rem 2rem;
}
.navbar-expand-lg .navbar-nav .nav-link{
    padding: 0 0.7rem;
}
.navbar-nav .nav-link{
    color: rgba(255, 255, 255, 0.767);
    text-transform: uppercase;
    font-size: 12pt;
}
.dropdown-menu{
    background:black;
}
.dropdown-item{
    padding: 0.50rem 0.70rem;
}
.dropdown-item, .navbar-nav .nav-link.show{
    color: rgba(255, 255, 255, 0.767);
}

.dropdown-item.active, .dropdown-item:active{
    background-color: transparent;
}