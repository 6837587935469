@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz@6..12&display=swap');

  .branches--wrapper .branches-heading,
  .usa-branches.main .usa--branches.heading
  {
    padding-bottom: 56px;
    padding-top: 112px;
    
  }

  .branches--wrapper .branches-heading div,
  .branches--wrapper .branches .branches-col,
  .usa-branches.main .usa-branches--heading div.heading,
  .usa-branches.main .usa-branches--heading div.heading-accordion{
    padding-left: 48px;
    padding-right: 48px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 1280px;
    margin: auto;
  }

  .branches--wrapper .branches-heading div div,
  .usa-branches.main .usa--branches.heading div div
  {
    width: 66.6666666667%;
    padding-bottom: 1.7rem;
    padding-top: 1.7rem;
    text-align: center;
    padding-left: 1.875%;
    padding-right: 1.875%;
  }

  .branches--wrapper .branches-heading div div h1,
  .usa-branches.main .usa-branches--heading div h1{
    color: rgba(55,71,79,1);
    /* font-size: 45pt; */
    font-size: 18.0pt;
    line-height: 1.1;
    letter-spacing: 2px;
    font-family: Lato, sans-serif;
    font-weight: 300;
    padding: 8px;
  }

  .branches--wrapper .branches-heading div div h1 span,
  .usa-branches.main .usa-branches--heading div h1 span{
    color: rgba(55,71,79,1);
    font-size: 18.0pt;
    line-height: 1.1;
    letter-spacing: 2px;
    font-family: 'Nunito Sans', Arial;
    font-weight: 300;
    vertical-align: baseline;
    padding: 8px;
    word-wrap: break-word;
  }

  .branches--wrapper .branches,
  .usa-branches.main .usa-branches--heading div.heading-accordion .accordion-item
{
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }

  .branches--wrapper .branches .branches-col .usa--wrapper,
  .branches--wrapper .branches .branches-col .philippines--wrapper,
  .branches--wrapper .branches .branches-col .middle-col{
    width: 16.6666666667%;
    
  }

  .branches--wrapper .branches .branches-col .first-col,
  .branches--wrapper .branches .branches-col .last-col{
    width: 25%;
  }

  .branches--wrapper .branches .branches-col .usa--wrapper .usa,
  .branches--wrapper .branches .branches-col .philippines--wrapper .philippines{
    padding-left: 7.5%;
    padding-right: 7.5%;
  }

  .branches--wrapper .branches .branches-col {
    display: flex;
    justify-content: space-around;
  }

  .branches--wrapper .branches .branches-col .usa--wrapper .usa div,
  .branches--wrapper .branches .branches-col .philippines--wrapper .philippines div{
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
    max-width: 1280px;
  }
  
  .branches--wrapper .branches .branches-col .usa--wrapper .usa div img,
  .branches--wrapper .branches .branches-col .philippines--wrapper .philippines div img{
    display: block;
    width: 100%;
    height: 90px;
  }

  .philippines--wrapper .philippines p a,
  .usa--wrapper .usa p a{
    color: #41545D;
  }

  .usa-branches.main .accordion-collapse{
    padding: 14px 8px;
  }

  .usa-branches.main .accordion-body{
    padding: 0;
  }

  .usa-branches.main .accordion-body .address-label{
    color: #575757;
    font-family: 'Arial';
    font-size: 12pt;
    font-weight: normal;
    vertical-align: baseline;
    margin-bottom: .70rem;
  }

  .usa-branches.main .accordion-body .address{
    background-color: transparent;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: none;
    line-height: 1.2;
    margin-bottom: 8pt;
    margin-left: 23pt;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-indent: 0;
  }

  .usa-branches.main .accordion-button:focus,
  .usa-branches.main .accordion-button:not(.collapsed){
    background-color: transparent;
    /* padding: 0; */
    color: #000000;
    font-family: 'Nunito Sans', Arial;
    font-size: 13.5pt;
    font-weight: 700;
    vertical-align: baseline;
    outline: 0;
    box-shadow: none;
  }

  .usa-branches.main .accordion-button{
    line-height: 1.32;
    margin-left: 11pt;
    margin-right: 11pt;
    padding-left: 0;
    text-indent: 0;
    color: rgba(33,33,33,1);
    font-family: Lato, sans-serif;
    font-size: 15pt;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 0rem;
  }

  .usa-branches.main .accordion-button div{
    color: #980000;
    font-family: 'Arial';
    font-size: 13.5pt;
    font-weight: normal;
    vertical-align: baseline;
  }

  .usa-branches.main .accordion-item hr{
    margin: 0.4rem 0;
    opacity: .50;
  }

  @media only screen and (max-width: 480px){
    .branches--wrapper .branches .branches-col{
      display: -webkit-box;
      display: -webkit-flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
    }

    .branches--wrapper .branches .branches-col .first-col,
    .branches--wrapper .branches .branches-col .last-col,
    .branches--wrapper .branches .branches-col .middle-col{
      display: none;
      width: 0%;
    }

    .branches--wrapper .branches .branches-col .usa--wrapper, 
    .branches--wrapper .branches .branches-col .philippines--wrapper{
      width: 50%;
    }

    .branches--wrapper .branches .branches-col {
      padding-left: 5px;
      padding-right: 5px;
    }
    .branches--wrapper .branches-heading div{
      padding-left: 0px ;
      padding-right: 0px;
    }
    .usa-branches.main .usa-branches--heading div.heading-accordion{
      padding-left: 1.50rem;
      padding-right: 1.50rem;
    }
    /* .branches--wrapper .branches-heading div div h1 span,
    .branches--wrapper .branches-heading div div h1{
      padding: 0px;
    } */
  }

   @media only screen and (max-width: 770px) and (min-width: 481px){
    .branches--wrapper .branches .branches-col{
      display: -webkit-box;
      display: -webkit-flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
    }

    .branches--wrapper .branches .branches-col .first-col,
    .branches--wrapper .branches .branches-col .last-col
    {
      display: none;
      width: 0%;
    }

    .branches--wrapper .branches .branches-col {
      padding-left: 5px;
      padding-right: 5px;
    }
    .branches--wrapper .branches-heading div{
      padding-left: 0px ;
      padding-right: 0px;
    }
    .branches--wrapper .branches .branches-col .usa--wrapper,
    .branches--wrapper .branches .branches-col .philippines--wrapper
    {
      width: 40%;
    }
    .branches--wrapper .branches .branches-col .middle-col{
      width: 10%;
    }
  }