.terms-condition section .content{
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 1280px;
    padding-left: 48px;
    padding-right: 48px;
}

.terms-condition--heading{
    font-family: 'Nunito Sans', Arial;
    font-weight: 600;
    vertical-align: baseline;
    font-size: 24.0pt;
    line-height: 1.2;
}

.terms-condition section{
    padding-bottom: 56px;
    padding-top: 112px;
    padding-left: 1.25%;
    padding-right: 1.25%;
}

.terms-condition .content p, .terms-condition .content ul li{
    color: #000000;
    font-family: 'Nunito Sans', Arial;
    font-size: 12.0pt;
    font-weight: 500;
    vertical-align: baseline;
}

.terms-condition .content ul li{
    margin-bottom: 15pt;
}

@media only screen and (min-width: 320px) {
    /* @media only screen and (min-width: 320px) and (max-width: 767px){ */
    .terms-condition section .content{
        /* max-width: max-content; */
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

}