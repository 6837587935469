.blog .blog--wrapper-1 .blog-col-1{
    padding-bottom: 56px;
    padding-top: 112px;
    height: 180px;
}

.blog .blog--wrapper-2{
    background-color: rgba(242,242,242,1);
}

.blog .blog--wrapper-2 section.accordion--wrapper .content{
    width: 100%;
    max-width: 1280px;
    padding-left: 48px;
    padding-right: 48px;
    margin-left: auto;
    margin-right: auto;
}

.blog .blog--wrapper-2 .accordion .accordion-header{
    margin-bottom: 0;
    padding: 14px 8px;
}

.blog .blog--wrapper-2 .accordion-button, 
.blog .blog--wrapper-2 .accordion-button:focus{
    background-color: transparent;
    padding: 0;
    font-family: Arial;
    font-size: 18.0pt;
    font-weight: 700;
    vertical-align: baseline;
    outline: 0;
    box-shadow: none;

    color: #c01217;
    font-weight: 700;
}

.blog .blog--wrapper-2 .accordion-body{
    /* padding: 1.25rem 0; */
    color: #000000;
    font-family: Arial;
    font-size: 12pt;
    font-weight: 400;
    vertical-align: baseline;
    padding: 14px 8px;
}

.blog .blog--wrapper-2 .accordion hr{
    border-bottom: 1px solid rgb(0 0 0);
    border-top: none; 
    opacity: .80;
    margin-bottom: 0;
}

.blog .blog--wrapper-2 .accordion--wrapper .accordion-item{
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}

.blog .blog--wrapper-2 .accordion .accordion-item .accordion-body ul li.list-item{
    margin-bottom: 3pt;
    margin-top: 11pt;
}

.blog .blog--wrapper-2 .content p{
    color: #000000;
    font-family: Arial;
    font-size: 12pt;
    font-weight: 400;
    vertical-align: baseline;
    text-align: justify;
}

.blog .blog--wrapper-2 .hidden--wrapper{
    display: block;
    margin-bottom: 0;
    padding-bottom: 45px;
    visibility: hidden;
    pointer-events: none;
}

.blog .list.bold li{
    font-weight: 700;
}

.blog .list.bold li p{
    margin-right: 38pt;
}

.blog .list.bold li span:nth-child(2){
    font-weight: 400;
}

.blog ol.bold li{
    list-style-type: decimal;
}

@media only screen and (min-width: 320px) {
    /* @media only screen and (min-width: 320px) and (max-width: 767px){ */
    .blog .blog--wrapper-2 section.accordion--wrapper .content{
        /* max-width: max-content; */
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

    .blog ul, .blog ol{
        padding-left: 1rem;
        padding-right: 1rem;
    }

}