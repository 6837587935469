.login{
    width: 100%;
    margin: auto;
    padding-top: 56px;
}
.login .login--banner-wrapper, .sub-footer{
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1280px;
    margin: auto;
    padding: 40px 48px;
}
.login .login-form--wrapper{
    background-color: rgba(242,242,242,1);
}

.login .login-form--wrapper .login--banner{
    width: 100%;
    max-width: 66.6666666667%;
    /* padding-left: 1.875%;
    padding-right: 1.875%; */
}

.login .login-form--wrapper .login--banner img{
    display: block;
    width: 100.0334961091%;
    margin: -0% 0 -0% -0.0167480545%;
}

.login .login--banner-wrapper .buttons, .login .login-form--wrapper .login--banner{
    padding-left: 3.75%;
    padding-right: 3.75%;
}

.login .login--banner-wrapper .buttons-wrapper{
    width: 100%;
    max-width: 33.3333333333%;
}

.login .login-form--wrapper .buttons p{
    padding: 14px 8px;
    word-wrap: break-word;
    margin-bottom: 0;
}

.login .ml-remit-btn{
    padding: 0.4rem;
    width: 100%;
}

.login p{
    font-size: 13.999999999999998pt;
}

.login div .sub-footer{
    color: rgba(33,33,33,1);
    font-family: Lato, sans-serif;
    font-size: 12.0pt;
    font-weight: 400;
    line-height: 1.6667;
    width: 100%;
    text-align: center;
}
@media only screen and (max-width: 770px){
    .login .login--banner-wrapper{
        flex-direction: column;
        align-items: center;
        padding: 40px 0;
    }
    .login .login--banner-wrapper .buttons-wrapper{
        padding-left: 0;
        padding-right: 0;
    }

    .login .login-form--wrapper .login--banner,
    .login .login--banner-wrapper .buttons-wrapper{
        margin: 10px;
    }

    .login .login--banner-wrapper .buttons-wrapper, .login .login-form--wrapper .login--banner{
        max-width: max-content;
    }
  }