.ml-us-services .parallax {
    /* The image used */
    background-image: url("../assets/april/product-banner.jpg");
  
    /* Set a specific height */
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    /* display: flex; */
    justify-content: center;

    height: 100vh;
  }

  .ml-us-servies .parallax-img{
    position: absolute;
    bottom: 0;
  }

  .ml-us-services .content section.heading--wrapper .heading{
    width: 83.3333333333%;
    margin: auto;
    padding-left: 1.5%;
    padding-right: 1.5%;
  }
  
  .ml-us-services .content section.heading--wrapper .heading p{
    padding: 8px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 1280px;

  }

  .ml-us-services .content section.heading--wrapper,
  .ml-us-services .heading--wrapper, .ml-us-services .kwarta-padala--wrapper,
  .ml-us-services .heading--wrapper, .ml-us-services .ml-remit--wrapper,
  .ml-us-services .heading--wrapper, .ml-us-services .quick-cash--wrapper,
  .ml-us-services .heading--wrapper, .ml-us-services .jewelry--wrapper{
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }

  .ml-us-services .content section.heading--wrapper,
  .ml-us-services .kwarta-padala--wrapper,
  .ml-us-services .heading--wrapper, .ml-us-services .ml-remit--wrapper,
  .ml-us-services .heading--wrapper, .ml-us-services .quick-cash--wrapper,
  .ml-us-services .heading--wrapper, .ml-us-services .jewelry--wrapper{
    width: 100%;
    max-width: 1280px;
    padding-left: 48px;
    padding-right: 48px;
    margin: auto;
  }

  .ml-us-services .content div p, 
  .ml-us-services .kwarta-padala--wrapper,
  .kwarta-padala--wrapper .kwarta-padala .services .list-item,
  .ml-us-services .heading--wrapper, .ml-us-services .ml-remit--wrapper,
  .ml-us-services .heading--wrapper, .ml-us-services .quick-cash--wrapper,
  .ml-us-services .heading--wrapper, .ml-us-services .jewelry--wrapper{
    word-wrap: break-word;
    color: #4f4f4f;
    font-family: 'Nunito Sans', Arial;
    font-size: 12.0pt;
    font-variant: normal;
    font-weight: 400;
    vertical-align: baseline;
  }
  .ml-us-services .content div p, 
  .ml-us-services .kwarta-padala--wrapper,
  .ml-us-services .heading--wrapper, .ml-us-services .ml-remit--wrapper,
  .ml-us-services .heading--wrapper, .ml-us-services .quick-cash--wrapper,
  .ml-us-services .heading--wrapper, .ml-us-services .jewelry--wrapper{
    line-height: 1.44;
    text-align: justify;
    
  }

  .ml-us-services .kwarta-padala--wrapper .kwarta-padala h3,
  .ml-us-services .kwarta-padala--wrapper .kwarta-padala div p,
  .ml-us-services .heading--wrapper, .ml-us-services .ml-remit--wrapper h3,
  .ml-us-services .heading--wrapper, .ml-us-services .quick-cash--wrapper h3,
  .ml-us-services .heading--wrapper, .ml-us-services .jewelry--wrapper h3{
    color: #000000;
    font-family: 'Nunito Sans', Arial;
    font-variant: normal;
    vertical-align: baseline;
    font-weight: 700;
  }
  .ml-us-services .kwarta-padala--wrapper .kwarta-padala p{
    margin-bottom: 50px;
  }

  .ml-us-services .kwarta-padala--wrapper .kwarta-padala h3,
  .ml-us-services .heading--wrapper, .ml-us-services .ml-remit--wrapper h3,
  .ml-us-services .heading--wrapper, .ml-us-services .quick-cash--wrapper h3,
  .ml-us-services .heading--wrapper, .ml-us-services .jewelry--wrapper h3{
    font-size: 18.0pt;
    padding: 12px 0;
  }

.ml-us-services .kwarta-padala--wrapper .kwarta-padala div p{
  font-size: 12.0pt;
  line-height: 1.44;
  margin-bottom: 14pt;
}
.kwarta-padala--wrapper .kwarta-padala .services .list-item{
  margin: 6px 0 0 0;
  list-style-type: square;
}

.kwarta-padala--wrapper .kwarta-padala .services .list-item,
.kwarta-padala--wrapper .kwarta-padala .services .list-item span{
  line-height: 1.38;
}

.kwarta-padala--wrapper .kwarta-padala .services{
  padding-inline-start: 20px;
}

.jewelry--wrapper .jewelry a{
  color: #B11112;;
}

@media only screen and (max-width: 770px){
    .ml-us-services .content section.heading--wrapper, 
    .ml-us-services .kwarta-padala--wrapper, 
    .ml-us-services .heading--wrapper, 
    .ml-us-services .ml-remit--wrapper, 
    .ml-us-services .heading--wrapper, 
    .ml-us-services .quick-cash--wrapper, 
    .ml-us-services .heading--wrapper, 
    .ml-us-services .jewelry--wrapper{
      padding: 30px 4%;
    }
    .content-1 h2{
      font-size: 20.0pt;
    }
    .content-3 .context h1, .content-4 h1{
      font-size: 23.0pt;
    }
    .content-3{
      flex-direction: column;
      align-items: center;
    }
    .content-3 .context, .content-3 .map, .content-5 div{
      max-width: max-content;
    }
    .content-6 div.wrapper div.link-wrapper{
      max-width: 50%;
    }
}