.main--wrapper{
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100vh;
}

.footer-space{
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;
}

.list-item{
  margin: 6px 0 0 0;
  list-style-type: square;
}

.accordion hr{
  border-bottom: 1px solid rgb(0 0 0);
  border-top: none; 
  opacity: .80;
}

.accordion-item{
  border: none;
  background-color: transparent;
}

.accordion-button, 
.accordion-button:focus{
    background-color: transparent;
    /* padding: 0; */
    color: #000000;
    font-family: 'Nunito Sans', Arial;
    font-size: 13.5pt;
    font-weight: 700;
    vertical-align: baseline;
    outline: 0;
    box-shadow: none;
}

.skip-link {
  background: #000000;
  color: #fff;
  font-weight: 700;
  left: 50%;
  padding: 4px;
  position: absolute;
  transform: translateY(-100%);
  font-size:14pt ;
}

.skip-link:focus {
  transform: translateY(0%);
}

.skip-link {
  /* Same as  before */
  transition: transform 0.3s;
}