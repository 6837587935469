@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz@6..12&display=swap');

.parallax {
    /* The image used */
    background-image: url("../assets/april/main\ banner.jpg");
  
    /* Set a specific height */
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

    height: 100vh;
  }

  .parallax-img{
    position: absolute;
    bottom: 0;
  }

  .parallax-img img{
    max-width: 100%;
    height: auto;
  }
  .content-1, .content-3, .content-4, .content-5, .content-6{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .content-1, .content-3, .content-5{
    max-width: 1280px;
    padding: 40px 48px;
    margin: auto;
  }
 .content-5 div img, .content-6 img{
    max-width: 100%;
    height: auto;
  }
  
  .content-1{
    text-align: center;
  }

  .content-2{
    background: fixed;
  }
  
  .content-2, .content-4, .content-6{
    padding: 1.5rem 0;
    text-align: center;
    background-color: rgba(242,242,242,1);
  }

  .branches--wrapper .branches{
    padding: 1.5rem 0;
    text-align: center;
    background-color: rgba(242,242,242,1);
    display: flex;
  }

  #send-now{
    background-color: rgba(50,50,50,1);
    padding: 0.4rem 2.9rem;
  }

  .content-3 .context{
    max-width: 58.3333333333%;
    width: 100%;
  }

  .content-3 p, .content-4 p:nth-child(2){
    color: rgba(33,33,33,1);
    font-family: Lato, sans-serif;
    font-size: 13.999999999999998pt;
    font-weight: 400;
    line-height: 1.6667;
    width: 100%;
  }
  .content-3 p{
    max-width: 1280px;

  }

  .content-3 h2, .content-4 h2{
    color: #850000;
    font-family: 'Nunito Sans', Arial;
    font-variant: normal;
    vertical-align: baseline;
    line-height: 1.2;
  }

  .content-3 .context h1, .content-3 .context p, .content-4 h2{
    padding: 14px 8px;
    word-wrap: break-word;
  }

  .content-3 .map{
    max-width: 33.3333333333%;
    width: 100%;
  }

  .content-3 .map img, .content-5 img{
    display: block;
    width: 100%;
  }

  .content-4 p:nth-child(3){
    font-size: 12pt;
    font-weight: 400;
    line-height: 1.6667;
    margin-top: 12px;
  }

  .content-5 div{
    width: 100%;
    max-width: 50%;
  }
  
  .content-6 div.wrapper{
    margin: auto;
    /* padding: 0 48px; */
    display: flex;
    width: 100%;
    max-width: 1280px;
    justify-content: center;
    align-items: center;
  }

  .content-6 div.wrapper div.link-wrapper{
    max-width: 16.6666666667%;
  }

  .content-6 div.link-wrapper div{
    padding: 0 7.5%;
  }
  
  .content-6 div div a{
    width: 100%;
    display: block;
  }

  .content-6 div a img{
    width: 100%;
    margin: 0% 0 0% -0.0000016028%;
  }

  @media only screen and (max-width: 770px){
    .content-1, .content-3, .content-4, .content-5{
      padding: 30px 4%;
    }
    .content-1 h2{
      font-size: 20.0pt;
    }
    .content-3 .context h1, .content-4 h1{
      font-size: 23.0pt;
    }
    .content-3{
      flex-direction: column;
      align-items: center;
    }
    .content-3 .context, .content-3 .map, .content-5 div{
      max-width: max-content;
    }
    .content-6 div.wrapper div.link-wrapper{
      max-width: 50%;
    }
  }