.disclosure.container-fluid .disclosure--header{
    background-color: #f8f8f8;
}
.disclosure .container{
    width: 1170px;
    margin: auto;

}
.disclosure .content .disclosure--heading h1{
    color: white;
}

.disclosure .content{
    margin-top: 15px;
}

.disclosure .disclosure--heading{
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 13px 30px;
    background-color: #b50918;
    border-radius: 10px;
    width: 1170px;
    margin: auto;
}

.disclosure .content .disclosure--sections{
    width: 1170px;
    margin: 60px auto 0px auto;
}

.disclosure .content .disclosure--sections p{
    font-size: 20px;
    text-align: justify;
}

.disclosure .content h1, .disclosure .content p, .disclosure .content span{
    font-family: 'Signika Negative', sans-serif;
}
.disclosure .content p, span{
    color: #58585b;

}